import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Tab,
  Tabs,
  Card,
  Container,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../components/Loader/Loader";
import dataServices from "../../apiServices/data.services";
import { useAlert } from "react-alert";
import tokenService from "../../apiServices/token.service";
import { Can } from "./../../context/AuthProvider";
import { handleValidationError } from "../../components/CommonFunctions";
import Image from "react-bootstrap/Image";
import FileUpload from "../../components/FileUploader/FileUpload";

const AppointmentDetails = () => {
  const { id } = useParams();
  const [appointment, setAppointment] = useState();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const navigate = useNavigate();
  const user = tokenService.getUser();
  const [reload, setReload] = useState(false);
  useEffect(() => {
    setLoading(true);
    dataServices
      .getAppointmentDetails(id)
      .then((res) => {
        if (res.status === 200) {
          setAppointment(res.data);
        }
      })
      .catch((err) => {
        alert.error(handleValidationError(err));
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reload]);

  const formik = useFormik({
    initialValues: appointment,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      dataServices
        .editAppointments(id, values)
        .then((res) => {
          if (res.status === 200) {
            alert.success("Diagnosis added successfully");
            navigate("/appointments");
          }
        })
        .catch((err) => {
          alert.error(handleValidationError(err));
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
  });
  const handleGenerateBill = () => {
    navigate("/add-invoice", { state: appointment });
  };
  const handleRecreateAppointment = () => {
    navigate("/request-appointment", { state: appointment });
  };

  return (
    <Container className="content-wrapper">
      <Row className="justify-content-center">
        <Can I="read" an="Appointment">
          <Col>
            <Card className="bg-white mt-4 rounded-4 mb-3">
              <Card.Body>
                {appointment ? (
                  <>
                    <Can not I="edit" a="appointment">
                      <Link
                        to={`/edit-appointment/${id}`}
                        className="link-dark"
                      >
                        <div className="edit-profile-icon bg-primary text-white">
                          <span className="material-symbols-outlined h2 m-0">
                            edit
                          </span>
                        </div>
                      </Link>
                    </Can>
                    <Card.Title>Appointment Details</Card.Title>
                    <Row className="d-flex">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Doctor Name</span>
                          <br />
                          {appointment.doctor?.name}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Date & Time</span>
                          <br />
                          {appointment.date + ", " + appointment.time}
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row className="d-flex">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Patient Name</span>
                          <Link></Link>
                          <br />
                          {appointment.patient?.name}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Age</span>
                          <br />
                          {appointment.patient.date_of_birth}
                        </Card.Text>
                      </Col>
                    </Row>
                    {appointment?.package &&
                      appointment?.package.id !== null ? (
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Selected Package</Form.Label>
                            <span> {appointment?.package.name}</span>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Remaing Seating</Form.Label>
                            <span>{appointment?.package.available_count}</span>/
                            <span>{appointment?.package.seating_count}</span>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <Form.Group className="form-group">
                            <Form.Label>Selected Package</Form.Label>
                            <span className="text-danger">
                              No active package
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    <Row className="d-flex">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Gender</span>
                          <br />
                          {appointment.patient.gender}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Fees</span>
                          <br />
                          {appointment.fee}
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Note</span>
                          <br />
                          {appointment.details}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Diagnosis</span>
                          <br />
                          {appointment.diagnosis}
                        </Card.Text>
                      </Col>
                    </Row>
                  </>
                ) : (
                  loading && <Loader />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Can>
      </Row>

      <Tabs
        defaultActiveKey="diagnosis"
        id="uncontrolled-tab-example"
        className="mb-3"
        mountOnEnter={true}
      >
        <Tab eventKey="diagnosis" title="Diagnosis">
          <p>Add Diagnosis</p>
          <Can I="add" an="Appointment" passThrough>
            {(allowed) => (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mt-2" controlId="diagnosis">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="diagnosis"
                    value={formik.values?.diagnosis || ""}
                    disabled={user.role === "STAFF"}
                    onChange={formik.handleChange}
                    placeholder="Type your Diagnosis here"
                    isInvalid={
                      formik.touched.diagnosis && formik.errors.diagnosis
                    }
                  />
                  {formik.touched.diagnosis && formik.errors.diagnosis ? (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.diagnosis}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
                <br />
                <Row>
                  <Form.Group className="mt-2" controlId="diagnosis">
                    <Form.Label>Daily note</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="doctor_note"
                      disabled={user.role === "STAFF"}
                      value={formik.values?.doctor_note || ""}
                      onChange={formik.handleChange}
                      placeholder="Note"
                      // isInvalid={formik.touched.fee && formik.errors.fee}
                    />
                    {/*  {formik.touched.fee && formik.errors.fee ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.fee}
                      </Form.Control.Feedback>
                    ) : null} */}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mt-2" controlId="diagnosis">
                    <Form.Label>Fees</Form.Label>
                    <Form.Control
                      type="number"
                      name="fee"
                      disabled={user.role === "STAFF"}
                      value={formik.values?.fee || ""}
                      onChange={formik.handleChange}
                      placeholder="Fee"
                      isInvalid={formik.touched.fee && formik.errors.fee}
                    />
                    {formik.touched.fee && formik.errors.fee ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.fee}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                </Row>
                <br />
                {user.role !== "STAFF" && (
                  <Button
                    variant="primary"
                    disabled={loading}
                    type="submit"
                    className="mt-3 w-100"
                  >
                    Submit
                  </Button>
                )}
                {user.role !== "DOCTOR" && (
                  <Button
                    variant="primary"
                    disabled={loading}
                    // type="submit"
                    onClick={handleGenerateBill}
                    className="mt-3 w-100"
                  >
                    Generate Invoice
                  </Button>
                )}
                <Button
                  onClick={handleRecreateAppointment}
                  variant="primary"
                  className="mt-3 w-100"
                >
                  Create Appointment
                </Button>
              </Form>
            )}
          </Can>
        </Tab>
        <Tab eventKey="uploadDocument" title="Upload Document">
          <p className="ms-3"> Upload Documents</p>
          <FileUpload
            id={id}
            uploadApi={"uploadAppointmentDocs"}
            editApi={"editAppointments"}
            assets={appointment?.assets}
            setReload={setReload}
          />
        </Tab>
      </Tabs>
      <br />
      <br />
    </Container>
  );
};

export default AppointmentDetails;
