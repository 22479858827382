import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import dataServices from "../../apiServices/data.services";
import Loader from "../../components/Loader/Loader";
import moment from "moment-timezone";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Can } from "./../../context/AuthProvider";
import { handleValidationError } from "../../components/CommonFunctions";
import { useAlert } from "react-alert";
import FileUpload from "../../components/FileUploader/FileUpload";
import tokenService from "../../apiServices/token.service";
import AppointmentDocs from "./AppointmentDocs";

const PatientProfile = () => {
  const { id } = useParams();
  const [patient, setPatient] = useState();
  const [appointments, setAppointments] = useState();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const alert = useAlert();
  const user = tokenService.getUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setLoading(true);
      dataServices
        .getPatientsDetails(id)
        .then((res) => {
          if (res.status === 200) {
            setPatient(res.data);
          }
        })
        .catch((err) => {
          alert.error(handleValidationError(err));
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
      dataServices
        .getAppointmentHistory(id)
        .then((res) => {
          if (res.status === 200) {
            setAppointments(res.data);
          }
        })
        .catch((err) => {
          alert.error(handleValidationError(err));
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, reload]);

  return (
    <Container className="content-wrapper">
      <Row className="justify-content-center">
        <Can I="read" a="Patient">
          <Col>
            <Card className="bg-white mt-4 rounded-4 mb-3">
              <Card.Body>
                {patient ? (
                  <>
                    <Can I="edit" a="Patient">
                      <Link to={`/edit-patient/${id}`} className="link-dark">
                        <div className="edit-profile-icon bg-primary text-white">
                          <span className="material-symbols-outlined h2 m-0">
                            edit
                          </span>
                        </div>
                      </Link>
                    </Can>
                    <Card.Title>Patient Info</Card.Title>
                    <Row className="mb-3">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Name</span>
                          <br />
                          {patient.contact.name}
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">
                            Registration Date
                          </span>
                          <br />
                          {patient.registration_date &&
                            moment(patient.registration_date).format(
                              "DD-MM-YYYY"
                            )}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">
                            Date of Birth
                          </span>
                          <br />
                          {patient.date_of_birth &&
                            moment(patient.date_of_birth).format("DD-MM-YYYY")}
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Gender</span>
                          <br />
                          {patient.gender}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Phone</span>
                          <br />
                          {user.role !== "DOCTOR"
                            ? patient.contact.mobile
                            : "**********"}
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Address</span>
                          <br />
                          {`${
                            patient?.address?.line1
                              ? patient?.address?.line1
                              : ""
                          } ${
                            patient?.address?.city ? patient?.address?.city : ""
                          } ${
                            patient?.address?.zipcode
                              ? patient?.address?.zipcode
                              : ""
                          }`}
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">
                            Package Details
                          </span>
                          <br />
                          <div className="d-flex justify-content-between">
                            <div>
                              <span className="text-muted small">Name: </span>
                              {patient?.package?.name}
                            </div>
                            <div>
                              <span className="text-muted small">
                                Seatings:
                              </span>
                              {patient?.package?.seating_count}
                            </div>
                            <div>
                              <span className="text-muted small">Amount: </span>
                              {patient?.package?.amount}
                            </div>
                          </div>
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">
                            Package Start Date
                          </span>
                          <br />
                          {patient.package_start_date &&
                            moment(patient.package_start_date).format(
                              "DD-MM-YYYY"
                            )}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">
                            Package End Date
                          </span>
                          <br />
                          {patient.package_end_date &&
                            moment(patient.package_end_date).format(
                              "DD-MM-YYYY"
                            )}
                        </Card.Text>
                      </Col>
                    </Row>
                  </>
                ) : (
                  loading && <Loader />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Can>
      </Row>
      <Button
        onClick={() =>
          navigate("/diagnosis-history", {
            state: { appointments, id: patient.id },
          })
        }
      >
        Diagnosis History
      </Button>
      <br />
      <br />
      <Tabs
        defaultActiveKey="appointmentHistory"
        id="uncontrolled-tab-example"
        className="mb-3"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Tab eventKey="appointmentHistory" title="Appointments">
          <Can I="list" an="Appointment">
            <div className="list">
              {appointments ? (
                appointments.map((appointment, index) => (
                  <div className="list-item" key={index}>
                    <Can I="edit" an="Appointment" passThrough>
                      {(allowed) => (
                        <Link
                          className="d-flex w-100"
                          to={`/appointment-details/${appointment.id}`}
                          disabled={!allowed}
                        >
                          <div className="list-item-content">
                            <span className="list-item__name">
                              <span className="mdi mdi-calendar-month text-primary me-1"></span>
                              {appointment.date}, {appointment.time}
                            </span>
                            <span className="list-item__info">
                              <strong className="text-dark">Note:</strong>
                              <br />
                              {appointment.details}
                            </span>
                            <br />
                            {appointment.diagnosis && (
                              <span className="list-item__info">
                                <strong className="text-dark">
                                  Diagnosis:
                                </strong>
                                {appointment.diagnosis}
                              </span>
                            )}
                          </div>
                        </Link>
                      )}
                    </Can>
                  </div>
                ))
              ) : loading ? (
                <Loader />
              ) : (
                <p>No history found!</p>
              )}
            </div>
          </Can>
        </Tab>
        <Tab eventKey="uploadDocument" title="Upload Documents">
          <p className="ms-3">Upload Documents</p>
          <FileUpload
            id={id}
            uploadApi="uploadPatientDocs"
            editApi="editPatients"
            assets={patient?.assets}
            setReload={setReload}
          />
        </Tab>
        <Tab eventKey="appointmentDocuments" title="Documents">
          <AppointmentDocs id={patient?.id} />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default PatientProfile;
